<template src="./index.html" />

<script>
import {
  BButton,
  BCol,
  BRow,
  BForm,
  BBreadcrumb,
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import '@core/scss/vue/libs/vue-good-table.scss'
import { mapActions, mapGetters } from 'vuex'
// eslint-disable-next-line import/extensions
import FormSchedule from '@/views/schedule/FormSchedule'
import { getUser } from '@/auth/utils'
import vSelect from 'vue-select'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'

export default {
  name: 'Scheduler',
  components: {
    BButton,
    BCol,
    BRow,
    BForm,
    BBreadcrumb,
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    vSelect,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    VueGoodTable,
    FormSchedule,
    BFormCheckbox,
  },
  props: {},
  data() {
    return {
      user: getUser(),
      table: {
        fields: [
          {
            field: 'subjectName', label: 'Học phần', sortable: false, type: 'string',
          },
          {
            field: 'creditClassCode', label: 'Lớp độc lập', sortable: false, type: 'string',
          },
          {
            field: 'roomName', label: 'Phòng học', sortable: false, type: 'string',
          },
          {
            field: 'schedule', label: 'Thời gian', sortable: false, type: 'string',
          },
          {
            field: 'teacherName', label: 'Giảng viên', sortable: false, type: 'string',
          },
          {
            field: 'action', tdClass: 'text-center', thClass: 'text-center', label: 'Thao tác', sortable: false,
          },
        ],
        items: [],
      },
      itemsDisplay: [],
      totalRecords: 0,
      loading: true,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: '',
          },
        ],
        page: 1,
        perPage: 10,
      },
      dataSendForm: {
        isCreated: 0,
      },
      filterExport: {
        type: null,
        trainingSystemId: '',
        courseId: '',
        courseSemesterId: '',
        yearWeekId: '',
        departmentId: '',
        subjectId: '',
        creditClassId: '',
        teacherId: '',
        dayId: '',
        organizationId: getUser().orgId,
      },
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        trainingSystemId: '',
        courseId: '',
        courseSemesterId: '',
        yearWeekId: '',
        departmentId: '',
        subjectId: '',
        creditClassId: '',
        teacherId: '',
        dayId: '',
        status: 1,
        maxStatus: 3,
        sort: '',
      },
      teacherOptions: [],
      titleNameDelete: '',
      checkRemoveAll: '0',
      itemDelete: {},
      checkAllWeek: '',
      isHandleSaveSuccess: false,
    }
  },
  computed: {
    // calculate data from init data
    ...mapGetters({
      schedulers: 'schedule/schedulers',
      total: 'schedule/total',
      courses: 'dropdown/courses',
      trainingSystems: 'dropdown/trainingSystems',
      courseSemesters: 'dropdown/courseSemesters',
      departments: 'dropdown/departments',
      subjects: 'dropdown/subjects',
      creditClasses: 'dropdown/creditClasses',
      teachers: 'dropdown/teachers',
      teachersByCreditClass: 'dropdown/teachersByCreditClass',
      days: 'dropdown/days',
      weeks: 'courseSemester/yearWeeks',
    }),
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.SCHEDULE)
    },
    updatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.SCHEDULE)
    },
    deletable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.SCHEDULE)
    },
  },
  async created() {
    await Promise.all([
      this.getTrainingSystems(),
      this.getDepartments(),
      this.getDays(),
    ])
    if (this.trainingSystems.length > 0) {
      this.filter.trainingSystemId = this.trainingSystems[0].value
      await this.getCourses(this.filter)
    }
    // init data FROM API
    // await this.getData();
  },
  methods: {
    ...mapActions({
      createSchedule: 'schedule/createSchedule',
      updateSchedule: 'schedule/updateSchedule',
      getSchedulers: 'schedule/getSchedulers',
      deleteSchedule: 'schedule/deleteScheduleByCreditClassAndRoomAndTeacher',
      getTrainingSystems: 'dropdown/getTrainingSystems',
      getCourses: 'dropdown/getCourses',
      getCourseSemesters: 'dropdown/getCourseSemesters',
      getDepartments: 'dropdown/getDepartments',
      getSubjects: 'dropdown/getSubjects',
      getCreditClasses: 'dropdown/getCreditClasses',
      getTeachers: 'dropdown/getTeachers',
      getTeachersByCreditClass: 'dropdown/getTeachersByCreditClass',
      getDays: 'dropdown/getDays',
      getWeeks: 'courseSemester/getYearWeek',
      exportScheduler: 'schedule/exportScheduler',
    }),
    // handle even
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getMainDataFromStore()
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getMainDataFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        const selectedColumn = this.columns.findIndex(e => e.field === field)

        this.updateParams({ sort: `${selectedColumn}_${type}` })
      }
      await this.getMainDataFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getMainDataFromStore()
    },
    openModal() {
      this.dataSendForm = {}
      this.$bvModal.show('modal-schedule')
    },
    // load items is what brings back the rows from server
    loadItems() {
      this.btnSearchClick()
    },
    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },

    async deleteItem(item) {
      this.itemDelete = item
      this.titleNameDelete = `Xóa lịch học tuần ${this.weeks.find(week => week.id === item.yearWeekId).code} của lớp độc lập ${item.creditClassCode}`
      this.$bvModal.show('modal-schedule-delete')
    },

    async handleOk(bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        this.itemDelete.checkRemoveAll = this.checkRemoveAll
        console.log(this.itemDelete)
        const result = await this.deleteSchedule(this.itemDelete)
        if (result) {
          await this.getMainDataFromStore()
          this.showToast('Đã xóa thành công!', 'CheckIcon', 'success', '')
          this.$bvModal.hide('modal-schedule-delete')
        } else {
          this.showToast('Có lỗi xảy ra!', 'XCircleIcon', 'danger', '')
        }
      } catch (e) {
        this.showToast(`[${e.code}] ${e.message}`, 'XCircleIcon', 'danger', '')
      }
    },

    async edit(data) {
      this.dataSendForm = data
      this.$bvModal.show('modal-schedule')
    },
    async onTrainingSystemsChange(event) {
      this.filter.trainingSystemId = event
      this.filter.courseId = null
      const request = { ...this.filter }
      request.itemsPerPage = 50
      await this.getCourses(this.filter)
    },
    async onCoursesChange(event) {
      this.filter.courseId = event
      this.filter.courseSemesterId = null
      await this.getCourseSemesters({ courseId: this.filter.courseId, status: null })
    },

    async onCourseSemesterChange() {
      await this.getWeeks({ courseSemesterId: this.filter.courseSemesterId })
      if (this.weeks && this.weeks.length > 0) {
        this.weeks.forEach(week => {
          week.code = `${week.code} (${week.startDate} - ${week.endDate})`
        })
      }
    },

    async onDepartmentChange(event) {
      this.filter.departmentId = event
      this.filter.subjectId = null
      this.filter.teacherId = null
      const request = { ...this.filter }
      request.itemsPerPage = 1000
      await Promise.all([
        this.getSubjects(request),
        this.getTeachers(request),
      ])
      this.teacherOptions = this.teachers
    },
    async onSubjectChange(event) {
      this.filter.creditClassId = null
      await this.getCreditClasses(this.filter)
    },
    async onCreditClassChange(event) {
      this.filter.teacherId = null
      const request = { ...this.filter }
      request.itemsPerPage = 500
      request.departmentId = null
      await this.getTeachersByCreditClass(request)
      this.teacherOptions = this.teachersByCreditClass
    },
    async saveDataForm(data) {
      const objData = {
        creditClassId: data.creditClassId,
        roomId: data.roomId,
        teacherId: data.teacherId,
        listDayCheck: data.listDayCheck,
        beginDate: data.beginDate,
        weeks: data.weeks,
      }
      // return;
      try {
        const res = await this.createSchedule(objData)
        if (res.code === 1) {
          if (data.type === 0) {
            this.$bvModal.hide('modal-schedule')
          }
          this.isHandleSaveSuccess = true
          this.showToast(res.message, 'CheckIcon', 'success', '')
        } else {
          this.showToast(res.message, 'XCircleIcon', 'danger', '')
        }
      } catch (e) {
        this.showToast(`[${e.code}] ${e.message}`, 'XCircleIcon', 'danger', '')
      }
      await this.getMainDataFromStore()
    },
    async getFileExport(type) {
      this.filterExport.type = type
      this.filterExport.trainingSystemId = this.filter.trainingSystemId
      this.filterExport.courseId = this.filter.courseId
      this.filterExport.courseSemesterId = this.filter.courseSemesterId
      this.filterExport.yearWeekId = this.filter.yearWeekId
      this.filterExport.departmentId = this.filter.departmentId
      this.filterExport.subjectId = this.filter.subjectId
      this.filterExport.creditClassId = this.filter.creditClassId
      this.filterExport.teacherId = this.filter.teacherId
      this.filterExport.dayId = this.filter.dayId
      try {
        if (!this.filter.trainingSystemId || !this.filter.courseId || !this.filter.courseSemesterId || !this.filter.yearWeekId) {
          this.showToast('Vui lòng chọn thông tin hệ đào tạo, khóa học, học kỳ và tuân thứ của học kỳ', 'XCircleIcon', 'warning', '')
          return false
        }
        const data = await this.exportScheduler(this.filterExport)
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'danh_sach_tkb.xls')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Có lỗi xảy ra!',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async getMainDataFromStore() {
      this.isLoading = true
      try {
        if (!this.filter.trainingSystemId || !this.filter.courseId || !this.filter.courseSemesterId) {
          this.showToast('Vui lòng chọn thông tin hệ đào tạo, khóa học và học kỳ', 'XCircleIcon', 'warning', '')
          return false
        }
        await this.getSchedulers(this.filter)
        if (this.schedulers.length > 0) {
          this.schedulers.forEach(item => {
            // eslint-disable-next-line no-param-reassign
            item.dayTurns = []
            const groupDays = item.groupDays.split(',')
            const groupDayIds = item.groupDayIds.split(',')
            const groupTurns = item.groupTurns.split('|')
            const groupTurnIds = item.groupTurnIds.split('|')
            let htmlDayTurn = ''
            const groupDayTurn = []
            if (groupDays.length > 0) {
              // eslint-disable-next-line no-plusplus
              for (let i = 0; i < groupDays.length; i++) {
                const dayTurn = { dayId: 0, turnIds: [] }
                groupTurns[i] = groupTurns[i].substr(0, 1).replace(',', '') + groupTurns[i].substring(1)
                htmlDayTurn += `<li class="m-0 p-0 list-group-item">&#128197;${groupDays[i]}: &#9200;[${groupTurns[i]}]</li>`
                dayTurn.dayId = groupDayIds[i]
                let turnIds = groupTurnIds[i].split(',')
                turnIds = turnIds.filter(t => t !== '')
                dayTurn.turnIds = turnIds
                groupDayTurn.push(dayTurn)
              }
            }
            // eslint-disable-next-line no-param-reassign
            item.schedule = `<ul class="m-0 p-0 list list-group-flush">${htmlDayTurn}</ul>`
            // eslint-disable-next-line no-param-reassign
            item.dayTurns = groupDayTurn
          })
        }
        this.table.items = this.schedulers
      } catch (e) {
        this.showToast(`[${e.code}] ${e.message}`, 'XCircleIcon', 'danger', '')
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
